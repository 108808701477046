.dark-scheme.de-grey,
.dark-scheme.de-grey footer,
.dark-scheme.de-grey footer a,
.dark-scheme.de-grey .box-url p,
.dark-scheme .profile_wallet{
    color: #ffffff;
}
body.dark-scheme.de-grey,
body.dark-scheme.de-grey #content,
.dark-scheme.de-grey header:not(.transparent),
.dark-scheme.de-grey .feature-box.f-boxed.style-3,
.dark-scheme.de-grey header.header-mobile,
.dark-scheme.de-grey .dropdown li span,
.dark-scheme.de-grey .dropdown li span:hover,
.dark-scheme .owl-prev, .dark-scheme .owl-next{
    /* background: url("../images/bg.jpg") no-repeat fixed 0 0 !important; */
    background-size: cover !important;
    background-color: #12101b!important;
}

.dark-scheme.de-grey header.smaller,
.dark-scheme.de-grey #mainmenu ul{
    background: #0a0812;
}

.dark-scheme.de-grey header.smaller{
    -webkit-box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
    -moz-box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
}

.dark-scheme.de-grey #mainmenu ul{
    -webkit-box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
    -moz-box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .4);
}



.de-grey .nft__item.style-2,
.nft_coll.style-2,
.de-grey .activity-list li,
.dark-scheme .activity-filter li,
.activity-list li:after,
.de-grey .item_info_counts>div{
    background: transparent;
    border-top: solid 0px rgba(255, 255, 255, .1);
    padding: 0px 0px 20px;
}
.de-grey .nft__item.style-2 .nft__item_wrap{
    background-color: #0a0812;
    height: 400px;
    /* clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 86%, 84% 100%, 20% 100%, 0 100%, 0% 20%); */
  border-radius: 5px;

}
.dark-scheme.de-grey .nft_pic{
    border: none;
}

.dark-scheme.de-grey .nft_coll{
    padding: 10px;
}
.dark-scheme.de-grey .nft_coll_pp img{
    border: none;
}

.dark-scheme.de-grey .nft__item img.nft__item_preview,
.dark-scheme.de-grey .nft_wrap,
.author_list_pp img,
.profile_avatar img,
.d_coll .profile_avatar img{
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.4);
    box-shadow: none;
}

.dark-scheme.de-grey .de_tab .de_nav li span{
    border: solid 1px rgba(255, 255, 255, .1);
    background: #212428;
}

.dark-scheme.de-grey .de_tab.de_steps .de_nav li.active{
    background: rgba(255, 255, 255, .3);
}

#form_subscribe input[type=text] {
    border-radius: 30px 0 0 30px;
    -moz-border-radius: 30px 0 0 30px;
    -webkit-border-radius: 30px 0 0 30px;
}
#form_subscribe #btn-subscribe i {
    border-radius: 0 30px 30px 0;
    -moz-border-radius: 0 30px 30px 0;
    -webkit-border-radius: 0 30px 30px 0;
}

#subheader .container{
    background: url(../images/collageBg.png) no-repeat scroll center bottom;
    background-size: cover;
    margin-top: 15px;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.de-grey #subheader h1{
    display: inline-block;
}
.mainFooter{
    background-color: #0d0b15 !important;
  }